<template>

    <button
        :class="[
            variant,
            size,
            'relative flex items-center justify-center cursor-pointer outline-none font-normal',
            { 'flex-row-reverse': reversed },
            { '!px-0 aspect-square': !text },
            ( rounded ? 'rounded-full' : 'rounded-md' ),
            ( stretch ? 'w-full' : 'w-fit' ),
            { 'pointer-events-none': loading || disabled },
            { 'selected': selected }
        ]"
        @click="callback && !disabled && !loading ? callback() : undefined; $event.currentTarget.parentElement.focus()"
        :disabled="disabled">
        
        <div id="button-loader" v-if="loading" :class="['border-2 rounded-full animate-spin aspect-square', ('loading-spinner-' + variant)]"/>

        <div v-else :class="['flex items-center gap-2.5', { 'flex-row-reverse': reversed }]">

            <p v-if="text" v-html="text" class="whitespace-nowrap"/>
            <i v-if="icon" id="icon" class="text-current" :class="icon" :style="[`transform: rotate(${rotateicon}deg)`]"/>
            <img v-if="image" id="icon" :src="image" :style="[`transform: rotate(${rotateicon}deg)`]"/>
        
        </div>

        <slot/>
        
    </button>

</template>

<script>

export default {

    props: {

        size: { type: String, default: 'md' }, // | lg | md | sm | xs |
        variant: { type: String, default: 'primary' }, // | primary | secondary | outlined | outlined_light | tinted | plain | default
        reversed: { type: Boolean, default: false },
        rounded: { type: Boolean, default: false },
        stretch: { type: Boolean, default: false },

        disabled: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },

        text: { type: String, default: '' },
        icon: { type: String, default: '' },
        image: { type: String, default: '' },
        rotateicon: { type: String, default: '0' },
        callback: {},
        selected: { type: Boolean },
    },
}
</script>

<style scoped>

button {
    transition: background 300ms, color 300ms, box-shadow 300ms;
}

.lg {
    height: fit-content;
    min-height: 46px;
    padding: 4px 32px;
    font-size: 14px;
    font-weight: 400;
}

.md {
    height: fit-content;
    min-height: 40px;
    padding: 4px 32px;
    font-size: 12px;
    font-weight: 400;
}

.sm {
    height: fit-content;
    min-height: 36px;
    padding: 0 24px;
    font-size: 12px;
}

.xs {
    height: fit-content;
    min-height: 28px;
    padding: 0 16px;
    font-size: 12px;
}

.lg #icon {
    max-height: 24px;
    font-size: 20px;
}

.sm #icon, .xs #icon, .md #icon {
    max-height: 20px;
    font-size: 16px;
}

.lg #button-loader, .md #button-loader {
    height: 20px;
}

.sm #button-loader {
    height: 16px;
}

.xs #button-loader {
    height: 14px;
}

/*===== slango_pro =====*/
.slango_pro {
    background: theme('backgroundImage.pro');
    color: white;
}

.slango_pro:hover {
    box-shadow: theme('boxShadow.weak_material');
}

.slango_pro:active {
    box-shadow: none;
}

/*===== primary =====*/
.primary {
    background: theme('colors.cta.75');
    color: white;
}

.primary:hover {
    background: theme('colors.cta.100');
    box-shadow: theme('boxShadow.primary');
}

.primary:active {
    box-shadow: none;
}

.primary:disabled {
    background: theme('colors.main.5');
    color: theme('colors.main.50');
}

/*===== secondary =====*/
.secondary {
    background: theme('colors.second.75');
    color: white;
}

.secondary:hover {
    background: theme('colors.second.100');
    box-shadow: theme('boxShadow.secondary');
}

.secondary:active {
    box-shadow: none;
}

.secondary:disabled {
    background: theme('colors.main.5');
    color: theme('colors.main.50');
}

/*===== main =====*/
.main {
    background: theme('colors.main.100');
    color: white;
}

.main:hover {
    background: theme('colors.main.100');
    box-shadow: theme('boxShadow.weak_material');
}

.main:active {
    box-shadow: none;
}

.main:disabled {
    background: theme('colors.main.10');
    color: theme('colors.main.60');
}

/*===== outlined =====*/
.outlined {
    border: 1px solid theme('colors.cta.75');
    color: theme('colors.cta.100');
}

.outlined:hover {
    border-color: theme('colors.cta.100');
    box-shadow: theme('boxShadow.primary');
}

.outlined:active {
    box-shadow: none;
}

.outlined:disabled {
    border-color: theme('colors.cta.50');
    color: theme('colors.cta.50');
}

/*===== outlined_light =====*/
.outlined_light {
    border: 1px solid theme('colors.wg.50');
    color: white;
}

.outlined_light:hover {
    border-color: white;
    box-shadow: theme('boxShadow.white');
}

.outlined_light:active {
    box-shadow: none;
}

/*===== outlined_secondary =====*/
.outlined_secondary {
    border: 1px solid theme('colors.second.75');
    color: theme('colors.second.100');
}

.outlined_secondary:hover {
    border-color: theme('colors.second.75');
    color: theme('colors.second.100');
    box-shadow: theme('boxShadow.secondary');
}

.outlined_secondary:active {
    box-shadow: none;
}

.outlined_secondary:disabled {
    border-color: theme('colors.second.75');
    color: theme('colors.second.50');
}

/*===== tinted =====*/
.tinted {
    background: theme('colors.cta.10');
    color: theme('colors.cta.100');
}

.tinted:hover {
    box-shadow: theme('boxShadow.primary');
}

.tinted:active {
    box-shadow: none;
}

.tinted:disabled {
    background: theme('colors.main.5');
    color: theme('colors.main.50');
}

/*===== plain =====*/
.plain {
    padding: 0 !important;
    color: white;
}

.plain:hover {
    text-decoration: underline;
}

.plain:disabled {
    color: theme('colors.main.50');
}

/*===== plain_dark =====*/
.plain_dark {
    @apply !p-0 !aspect-auto text-main-75 hover:text-main-100 disabled:!text-main-50;
}

/*===== pro =====*/
.pro {
    background: theme('backgroundImage.pro_dark');
    color: theme('colors.main.5');
}

.pro:hover {
    box-shadow: theme('boxShadow.weak_material');
}

.pro:disabled {
    opacity: 0.5;
}

.pro:active {
    box-shadow: none;
}


/*===== default =====*/
.default {
}

.default:hover {
    @apply text-main-100 bg-main-10 shadow-weak_material;
}

.default:disabled {
    opacity: 0.5;
}

.default:active {
    box-shadow: none;
}
</style>
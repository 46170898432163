import { user, install, MakeRequest } from '@/helpers/APIconnection' // eslint-disable-line
import { InitializeDefaultTranslation, secureTranslation } from '@/i18n'
import { ref } from 'vue'
import store from '@/store'
import router from '@/router'
import utils from '@/slango-multiverse/helpers/utils'
import { desktopAppComunication, desktopPostMessage, desktopAppData, desktopAddListener, desktopRemoveListener } from '@/helpers/desktop-controller' // eslint-disable-line
import customProtocolCheck from 'custom-protocol-check'
import { PublicClientApplication } from '@azure/msal-browser'

export async function InitApplication(properties) {

    store.state.appGlobalLoading = {}

    // ===============|  Translations  |===============

    store.state.appGlobalLoading.translations = true
    InitializeDefaultTranslation().then(() => store.state.appGlobalLoading.translations = false)

    // ===============|  Navigation middleware  |===============

    router.beforeEach(async (to, from, next) => {

        if ( to.meta.debug && process.env.VUE_APP_ENV !== 'dev' ) { next({ path: '/' }); return }
        if ( to.name === 'Auth' ) { store.state.ui = {} }

        if ( from.path === '/' ) { await embeddedInit() }

        if ( !store.state.defaultRedirect ) {

            store.state.defaultRedirect = () => {
    
                if ( to.path === '/' || to.redirectedFrom?.path === '/' || to.name === 'Auth' ) { // >> Ruta por defecto desde Root o Auth
    
                    const defaultRoute = store.state.currentUser?.userData?.group?.defaultRoute

                    if ( defaultRoute ) {
    
                        router.push({ path: defaultRoute })
                        return

                    } else { router.push({ name: 'Root' }) }
                    
                } else { router.push({ path: to.path }) }
            }
        }

        if ( to.meta.requiresAuth && !store.state.currentUser ) { // >> If next route requires auth check authentication status

            store.state.appGlobalLoading.restoreSession = true

            const sessionRestored = await user.restoreSession()

            if ( sessionRestored ) { store.state.defaultRedirect() }
            else { next({ name: 'Auth' }) }
            
            store.state.appGlobalLoading.restoreSession = false

            if ( !sessionRestored ) return
        }

        if ( to.meta.allowed && !to.meta.allowed() ) {

            next(from)
            return
        }

        if ( to.meta.requiresPayment && !store.getters.currentUserSubscription.active ) {

            store.state.ui.promptModal = {

                title: secureTranslation('payments.modals.payment-required.title'),
                text: secureTranslation('payments.modals.payment-required.text'),
                bully: false,
                onclose: () => store.state.ui.promptModal = undefined,
                footer: [
                    { click: () => { router.push({ name: 'Payments' }); store.state.ui.promptModal = undefined }, text: secureTranslation('payments.modals.payment-required.choose-plans'), center: true }
                ]
            }

            next(false)
            return
        }

        next()
    })

    // ===============|  Global methods  |===============

    const installDesktopApp = async () => {
                    
        const res = await install.recorder()

        if ( res?.status === 200 ) { properties.$download({ blob: res.data, filename: 'Slango Desktop Setup', extension: '.zip' }) }
        store.state.ui.promptModal = undefined
    }

    const AttemptToOpenRobotekaFailed = () => {

        const cancelButton = { variant: 'outlined', text: secureTranslation('general.cancel'), click: () => store.state.ui.promptModal = undefined }
        const downloadButton = { variant: 'primary', text: secureTranslation('home.download-installer'), click: async () => {

            const res = await install.slango()
            
            if ( res?.status === 200 ) { properties.$download({ blob: res.data, filename: 'Slango Setup', extension: '.exe' }) }
            store.state.ui.promptModal = undefined
        }}

        store.state.ui.promptModal = {
    
            title: secureTranslation('app.roboteka.installation.title'),
            text: secureTranslation('app.roboteka.installation.text'),
            footer: [ cancelButton, downloadButton ]
        }
    }
    
    const AttemptToOpenRecorderFailed = () => {
    
        store.state.ui.promptModal = {
    
            title: secureTranslation('app.recorder.installation.title'),
            text: secureTranslation('app.recorder.installation.text'),
    
            footer: [
    
                { variant: 'outlined', text: secureTranslation('general.cancel'), click: () => store.state.ui.promptModal = undefined },
                { variant: 'primary', text: secureTranslation('home.download-installer'), click: installDesktopApp }
            ]
        }
    }

    properties.$st = secureTranslation
    properties.$checkEmbedded = () => { return store.state.embedded = properties.$embedded = /SlangoDesktop/i.test(navigator.userAgent) }

    properties.$openExternal = (url, sameTab) => { if ( !url ) return; properties.$checkEmbedded() ? undefined : window.open(url, sameTab ? '_self' : '') }
    properties.$openDesktopApp = (url, failCallback, successCallback, timeout = 2000) => customProtocolCheck(url, failCallback, successCallback, timeout)
    properties.$openSlangoDesktop = () => properties.$openDesktopApp('slango://', AttemptToOpenRobotekaFailed, undefined, 1000)

    properties.$openTaskRecorder = (showModal = true) => {
        
        if (properties.$checkEmbedded()) { return }
        properties.$openDesktopApp('SlangoTaskRecorder://', showModal ? AttemptToOpenRecorderFailed : installDesktopApp, undefined, 1000)
    }

    properties.$onlyOnDesktop = () => {

        store.state.ui.promptModal = {
    
            title: 'Estás usando Slango Web',
            text: 'Esta acción solo está disponible en nuestra aplicación de escritorio.',
    
            footer: [
    
                { variant: 'outlined', text: secureTranslation('general.cancel'), click: () => store.state.ui.promptModal = undefined },
                { variant: 'primary', text: secureTranslation('home.download-installer'), click: () => properties.$openTaskRecorder(false) }
            ]
        }
    }

    properties.$desktopAppComunication = desktopAppComunication
    properties.$desktopPostMessage = desktopPostMessage
    properties.$desktopAddListener = desktopAddListener
    properties.$desktopRemoveListener = desktopRemoveListener
    
    properties.$noXSS = (value) => value?.replaceAll(/[<>"'&]/g, c => '&' + ({ '<': 'lt', '>': 'gt', '&': 'amp', "'": 'apos', '"': 'quot' })[c] + ';')

    // >> Inputs validation

    properties.$registeredInputs = { all: {} }

    properties.$validate = (group = 'all') => {

        if ( !properties.$registeredInputs ) return true

        const inputs = Object.values(properties.$registeredInputs[group] ?? {})

        return inputs.map(i => {
            
            if ( i.validate ) return i.validate()
            return true
        
        }).every(i => i)
    }

    Object.entries(utils ?? {}).forEach(method => properties['$' + method[0]] = method[1])

    // =====| Toast messages

    const toastMessages = ref({})
    const timeouts = {}

    properties.$toasts = toastMessages.value

    properties.$createToastMessage = (options) => {

        if ( options.key !== undefined ) { options.key = (options.title ?? '' + options.text ?? '') }
        
        clearTimeout(timeouts[options.key])
        properties.$toasts[options.key] = options

        if ( options.alive ) { timeouts[options.key] = setTimeout(() => delete properties.$toasts[options.key], options.alive) }
    }

    properties.$clipboard = (text) => {
        
        if (text) {
            
            navigator.clipboard.writeText(text)
            properties.$createToastMessage({ key: 'clipboard', text: 'Copiado!', alive: 1500, fixed: true, close: false })
        }
    }

    properties.$microsoftLogin = async () => {

        const msalConfig = {

            auth: {
                clientId: '3e77936b-5376-49f8-9f1f-ec34a663a7dd',
                authority: 'https://login.microsoftonline.com/common',
                redirectUri: '/', // La URL a la que se redirigirá después de iniciar sesión
            },
            cache: {
                cacheLocation: "localStorage", // Especifica la ubicación del almacenamiento en caché
                storeAuthStateInCookie: false, // Establece si debe almacenar el estado de autenticación en una cookie
            }
        }

        const msalInstance = new PublicClientApplication(msalConfig)

        await msalInstance.initialize()

        try {

            await msalInstance.loginPopup({ prompt: 'select_account' })

        } catch (error) {

            console.error('Error al iniciar sesión:', error)
        }
    }

    properties.$navigationMenu = (container, current) => {

        if ( !container || !(container instanceof HTMLElement) ) { return }
    
        let underline = container.querySelector('#active-element')

        const children = Array.from(container.children)
    
        if ( !underline ) {
    
            container.classList.add('relative')
            underline = document.createElement('div')
            underline.id = 'active-element'
            underline.classList.add('absolute', 'bottom-0', 'h-0.5', 'bg-current')
            container.appendChild(underline)
        }

        underline.style.transition = 'width 200ms, left 200ms'
    
        current = typeof current === 'string' ? container.querySelector('.' + current) : children[current]

        if ( !current ) return

        children.forEach(c => c.classList.remove('active-element'))
        current.classList.add('active-element')
    
        underline.style.width = current.clientWidth + 'px'
        underline.style.left = current.offsetLeft + 'px'
    }

    // =====| Timers

    const timers = {}

    properties.$newTimer = ({ key, callback = () => {}, duration = 30 }) => {

        const timer = timers[key] = ref({ callback })

        timer.value.clear = () => {
            
            clearInterval(timer.value.interval)
        }

        clearInterval(timer.value.interval)

        timer.value.remaining = duration

        timer.value.interval = setInterval(() => {

            timer.value.remaining --

            if ( timer.value.remaining <= 0 ) {

                timer.value.callback()
                timer.value.clear()
                delete timers[key]
            }

        }, 1000)

        return timer
    }

    const embeddedInit = async () => {

        store.state.appGlobalLoading.embedded = true

        if ( properties.$checkEmbedded() ) {

            // check if is necesary update

            const versions = await install.getDesktopVersions()

            if (versions) {

                const versionToDesktop = Object.entries(versions.data).map(([key, value]) => {

                    const [tag, version] = key.split("-")
                    return { tag, version, ...value }
                })

                const checkupdate = await desktopAppComunication("CheckAppUpdate", versionToDesktop) // TODO - Esta comprobación se puede hacer aquí embebiendo en navigator.userAgent la información de la app

                if (checkupdate === "forceUpdate") {

                    store.state.ui.promptModal = {

                        title: "¡Hay disponible una nueva actualización!",
                        text: "Esta actualización es <b>obligatoria</b> y no puedes continuar utilizando la aplicación sin descargarla, obtendrás las últimas mejoras.",
                        footer: [
                            { click: () => { desktopAppComunication("RunInstaller") }, text: "Actualizar", stretch: true, size: "sm" }
                        ]
                    }

                    // Forzar actualización await desktopAppComunication("RunInstaller")

                } else if (checkupdate === "optionalUpdate") { // TODO - In optional updates, if refreshApp, don't ask again

                    store.state.ui.promptModal = {

                        title: "¡Hay disponible una nueva actualización!",
                        text: "Esta actualización es <b>opcional</b> y puedes continuar utilizando la aplicación sin necesidad de descargarla aunque recomendamos que la lleves a cabo para obtener las ultimas mejoras.",
                        footer: [
                            { click: () => store.state.ui.promptModal = undefined, text: "En otro momento", variant: 'outlined', stretch: true, size: "sm" },
                            { click: () => { desktopAppComunication("RunInstaller") }, text: "Actualizar", stretch: true, size: "sm" }
                        ]
                    }
                }
            }

            clearInterval(appStatusInterval)

            // TODO - Change it to listener
            var appStatusInterval = setInterval(async () => { store.state.appStatus = await desktopAppData.AppStatus }, 1000)
        }

        store.state.appGlobalLoading.embedded = false
    }
}
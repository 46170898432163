import { createI18n } from 'vue-i18n'
import { desktopAppComunication } from '@/helpers/desktop-controller'
import { GetTranslations, user } from '@/helpers/APIconnection'
import store from '@/store'

const isTranslationCode = /\./

let translationsMessages = []

// Custom translation method to avoid undefined errors, this will be used as vue global property ($ct)
export function secureTranslation(text, fields = {}, isPlural) {
  
  if (text && isTranslationCode.test(text)) { return i18n.global.t(text, fields, isPlural) }
  return text
}

let stResolve
secureTranslation.waitReady = new Promise(res => stResolve = res)

export async function InitializeDefaultTranslation() {

  const locale = process.env.IS_ELECTRON ? 'en' : navigator.language.split('-')[0] || 'en'

  await SetI18nLanguage(locale)
  stResolve()
}

export async function GetTranslation(locale) { // # Get language messages

  const response = await GetTranslations(locale)
  
  if ( response && response.status === 200 ) {
    
    i18n.global.setLocaleMessage(locale, response.data)
    translationsMessages.push(locale)
    desktopAppComunication("UpdateTranslations", { locale, translations: response.data })
    return true
  }
}

export const messages = () => i18n.global.messages.value[i18n.global.locale.value]

export async function SetI18nLanguage(locale) { // # Change translation

  if ( store.state.currentUser ) { user.update({ language: locale }) }
  
  if ( !translationsMessages.includes(locale) ) {
    const response = await GetTranslation(locale)
    if ( !response ) { return } // Translation request failed
  }

  i18n.global.locale.value = locale
  document.querySelector('html').setAttribute('lang', locale)
}

export const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  missingWarn: false,
  fallbackWarn: false,
  warnHtmlMessage: false,
  messages: {}
})

export default i18n
<template>

    <div class="relative h-full w-full flex flex-col overflow-hidden transition-[background]">

        <div v-if="this.globalLoading" class="fixed inset-0 flex items-center justify-center">
            
            <div class="relative w-[35%] max-w-[250px] flex items-center justify-center aspect-square">
                
                <img src="@/assets/graphics/slang_login_logo.svg" alt="" class="top-1/2 left-1/2 -translate-1/2 w-[60%] animate-fade">
                <div class="absolute !h-full !w-full border-4 rounded-full border-white/10 border-t-white animate-spin"/>

            </div>

        </div>

        <TopBar :hidden="this.globalLoading || !showApplicationTools.topbar"/>

        <div v-if="!this.globalLoading" class="relative h-full w-full flex overflow-hidden">

            <NavigationBar
                v-if="showApplicationTools.navigation"
                class="z-50"/>

            <div class="relative h-full w-full flex flex-col rounded-tl-lg overflow-hidden">

                <img src="@/assets/graphics/app_background.png" class="absolute inset-0 object-cover min-h-full min-w-full pointer-events-none" style="z-index: -100" alt="">

                <router-view/>

            </div>

        </div>

        <SubscriptionOnboarding v-if="this.store.state.ui.paymentOnboardingPromise && this.$route.name !== 'Auth'"/>

        <Banners/>

        <!-- User Prompt Modal -->
        <Modal
            v-if="this.store.state.ui.promptModal"
            v-bind="this.store.state.ui.promptModal"
            :fixedWidth="true"/>
            
        <SlangoNews v-if="this.store.state.ui.slangoNews"/>
        <AppDevTools v-if="this.ShowAppDevTools"/>

        <div class="fixed w-full left-1/2 bottom-0 -translate-x-1/2 flex flex-col items-center gap-2 px-4 pb-4 z-50 pointer-events-none">

            <TransitionGroup name="fade-from-left">

                <div
                    v-for="toast, key in this.$toasts" :key="toast"
                    class="relative w-full max-w-[600px] flex gap-3 p-3 px-4 rounded-md"
                    :class="[
                        {'bg-main-100 text-main-5 shadow-material': !toast.type},
                        {'bg-error text-main-5': toast.type === 'error'},
                        {'bg-second-100 shadow-material text-main-5': toast.type === 'secondary'},
                        {'!w-fit': toast.fixed}
                    ]">

                    <i v-if="toast.leadingIcon" :class="toast.leadingIcon" class="text-lg"/>

                    <div class="w-full flex flex-col justify-center gap-1 pointer-events-auto">

                        <p v-if="toast.title" v-html="toast.title" class="font-medium"/>
                        <p v-if="toast.text" v-html="toast.text" class="text-xs"/>

                    </div>

                    <i v-if="toast.icon" :class="toast.icon" class="text-lg"/>

                    <i v-if="toast.close !== false" class="icon-cross absolute top-0 right-0 text-current text-2xl cursor-pointer pointer-events-auto" @click="delete this.$toasts[key]"/>

                </div>

            </TransitionGroup>

        </div>

    </div>
    
</template>

<script>
import { defineAsyncComponent } from 'vue'
import store from '@/store'
import TopBar from '@/components/TopBar'
import NavigationBar from '@/components/NavigationBar'

const Modal = defineAsyncComponent(() => import('@/slango-multiverse/components/Modal.vue'))
const AppDevTools = defineAsyncComponent(() => import('@/components/AppDevTools.vue'))
const Banners = defineAsyncComponent(() => import('@/components/Banners.vue'))
const SlangoNews = defineAsyncComponent(() => import('@/components/SlangoNews.vue'))
const SubscriptionOnboarding = defineAsyncComponent(() => import('@/components/SubscriptionOnboarding.vue'))

export default {

    data: function() {

        return {
            
            store
        }
    },

    components: { TopBar, NavigationBar, Modal, AppDevTools, Banners, SlangoNews, SubscriptionOnboarding },

    computed: {

        ShowAppDevTools() { return process.env.VUE_APP_ENV === 'dev' },

        globalLoading() { return Object.values(store.state.appGlobalLoading).some(l => l) },

        showApplicationTools() {

            return {
                    
                navigation: typeof this.$route.meta.navigation === 'function' ? this.$route.meta.navigation() : this.$route.meta.navigation,
                topbar: typeof this.$route.meta.topbar === 'function' ? this.$route.meta.topbar() : this.$route.meta.topbar
            } 
        }
    },
}
</script>

<style>
.session-restore-loading {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.session-restore-loading .wrapper {
    position: relative;
    width: 35%;
    max-width: 250px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.session-restore-loading .wrapper img {
    position: absolute;
    width: 60%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    animation: fade 0.5s;
}

.session-restore-loading .wrapper .spinner {
    position: absolute;
    height: 100%;
    width: 100%;
    border: 3px solid transparent;
    border-top-color: #FFF;

    border-radius: 50%;
    z-index: 1;

    animation: restore-spin 1s linear infinite;
}

.session-restore-loading .wrapper .spinner.rail {
    border-color: theme('colors.main.75');
    opacity: 0.5;
    z-index: 0;
    animation: none;
}

@keyframes restore-spin { 100% { transform: rotate(360deg) } }
</style>
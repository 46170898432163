const axios = require('axios')
import store from '@/store'
import { SetI18nLanguage, secureTranslation } from '@/i18n'
import { TIMEOUT, STORAGE_KEYS } from '@/utils/constants'
import router from '@/router'
import { reactive } from 'vue'
import { desktopAppComunication } from "@/helpers/desktop-controller"

axios.defaults.withCredentials = true

export const MakeRequest = reactive({

    baseURL: '',
    connectedTo: process.env.VUE_APP_ENV,
    host: window.location.protocol === 'app:' ? process.env['VUE_APP_HOST_SERV_' + process.env.VUE_APP_ENV.toUpperCase()] : window.location.host,
    origin: window.location.protocol === 'app:' ? 'https://' + process.env['VUE_APP_HOST_SERV_' + process.env.VUE_APP_ENV.toUpperCase()] : window.location.origin,

    buildHeaders: (headers = {}) => {

        const defaults = { clientApp: `${/SlangoDesktop/i.test(navigator.userAgent) ? 'RobotekaDesktop' : 'RobotekaWeb'}:${require('../../package.json').version}` }

        if ( store.getters.lang ) { defaults.lang = store.getters.lang }

        return { ...defaults, ...headers }
    },

    connectTo: (connection) => {

        if ( !connection ) {
            
            if ( process.env.VUE_APP_ENV === 'dev' ) connection = localStorage.getItem('last-connection') ?? 'dev'
            else connection = process.env.VUE_APP_ENV
        }

        MakeRequest.connectedTo = connection

        switch(connection) {

            case 'prod':
                
                MakeRequest.baseURL = `https://${process.env.VUE_APP_HOST_API_PROD}${process.env.VUE_APP_BASE_API_PATH}`

                break

            case 'dev':

                MakeRequest.baseURL = `https://${process.env.VUE_APP_HOST_API_DEV}${process.env.VUE_APP_BASE_API_PATH}`

                break

            case 'test':
                
                MakeRequest.baseURL = `https://${process.env.VUE_APP_HOST_API_TEST}${process.env.VUE_APP_BASE_API_PATH}`
                break

            case 'local':

                MakeRequest.baseURL = `${window.location.protocol}//${window.location.hostname}:${process.env.VUE_APP_LOCAL_API_PORT}${process.env.VUE_APP_BASE_API_PATH}`
        }

        desktopAppComunication("SyncSession", { serverAdress: MakeRequest.baseURL })

        if ( process.env.VUE_APP_ENV !== 'prod' ) {

            console.log(`%c[${connection.toUpperCase()} ${/SlangoDesktop/i.test(navigator.userAgent) ? 'Desktop' : 'Web'}]%cConnected to ${MakeRequest.baseURL}`, 'color:cyan;font-size:24px', 'font-size:20px')
        }

        localStorage.setItem('last-connection', connection)
    },

    buildUrl: (path) => {

        return (path.match(/^https?:\/\//) ? '' : MakeRequest.baseURL) + path;
    },

    get: async (url, options = {}, catch401 = true) => {

        const { headers, ...optionsWithoutHeaders } = options
        const req = async () => axios.get(MakeRequest.buildUrl(url), { headers: MakeRequest.buildHeaders(headers), ...optionsWithoutHeaders })

        return req().catch(err => catch401 ? HandleAPIErrors(err, req) : err)
    },

    delete: async (url, data, options = {}, catch401 = true) => {

        const { headers, ...optionsWithoutHeaders } = options
        const req = async () => axios.delete(MakeRequest.buildUrl(url), { headers: MakeRequest.buildHeaders(headers), ...optionsWithoutHeaders, data })

        return req().catch(err => catch401 ? HandleAPIErrors(err, req) : err)
    },

    patch: async (url, data, options = {}, catch401 = true) => {

        const { headers, ...optionsWithoutHeaders } = options
        const req = async () => axios.patch(MakeRequest.buildUrl(url), data, { headers: MakeRequest.buildHeaders(headers), ...optionsWithoutHeaders })

        return req().catch(err => catch401 ? HandleAPIErrors(err, req) : err)
    },

    post: async (url, data, options = {}, catch401 = true) => {

        const { headers, ...optionsWithoutHeaders } = options
        const req = async () => axios.post(MakeRequest.buildUrl(url), data, { headers: MakeRequest.buildHeaders(headers), ...optionsWithoutHeaders })

        return req().catch(err => catch401 ? HandleAPIErrors(err, req) : err)
    },

    put: async (url, data, options = {}, catch401 = true) => {

        const { headers, ...optionsWithoutHeaders } = options
        const req = async () => axios.put(MakeRequest.buildUrl(url), data, { headers: MakeRequest.buildHeaders(headers), ...optionsWithoutHeaders })

        return req().catch(err => catch401 ? HandleAPIErrors(err, req) : err)
    }
})

MakeRequest.connectTo()

export function GetBaseURL() { return MakeRequest.baseURL }
export function GetOrigin() { return MakeRequest.origin }

async function HandleAPIErrors(error, request) {

    if ( !error ) { return }
    
    error = error.response || error

    if ( error.status === 401 ) {

        if ( !HandleAPIErrors.tokenPromise ) { HandleAPIErrors.tokenPromise = user.refreshToken() }
        const refreshToken = await HandleAPIErrors.tokenPromise
        delete HandleAPIErrors.tokenPromise

        if ( refreshToken?.status === 200 ) { return await request() }

        // >> Logout reason
        if ( error.data?.reason ) { store.commit('CreateToastMessage', { id: 'otherLocationDetected', type: 'error', title: secureTranslation('logoutReason.' + error.data?.reason), timeAlive: 5000 }) }
        
        router.push({ name: 'Auth' })
    }

    if ( error && error.code === "ECONNABORTED") { return { status: 408 } }
    
    return error
}

// ==================== API - USER ====================

export const user = {

    auth: async (email, password, utoken) => {

        const data = email && password ? { email, password } : {}

        const response = await MakeRequest.post('auth/' + (utoken ? 'logintoken' : 'login') + '?customres=true', data)

        if ( response?.status === 200 ) {
            
            // Get user language messages
            if ( response.data.userData && response.data.userData.language ) { await SetI18nLanguage(response.data.userData.language) }

            store.commit('SetCurrentUser', response.data)
            desktopAppComunication("SyncSession", { user: response.data })

            subscription.balance()
        }

        return response
    },

    googleAuth: async (token) => {

        const body = { 'id_token': token }

        const response = await MakeRequest.post('auth/google', body)
    
        if ( response?.status === 200 ) {
            
            // Get user language messages
            if ( response.data.userData?.language ) { await SetI18nLanguage(response.data.userData.language) }

            store.commit('SetCurrentUser', response.data)
            desktopAppComunication("SyncSession", { user: response.data })
            
            subscription.balance()
        }

        return response
    },
    
    logout: async () => {
    
        router.push({ name: 'Auth' })
        store.state.currentUser = undefined
    
        Object.keys(localStorage).filter(elem => elem.match(STORAGE_KEYS.REMOVE_ON_LOGOUT_REGEX)).forEach(localStorage.removeItem.bind(localStorage))
    
        const res = await MakeRequest.post("auth/logout", {}, undefined, false)

        return res
    },

    refreshToken: async () => {

        const res = await MakeRequest.post('auth/newtoken', {}, {}, false)

        return res
    },

    restoreSession: async () => {

        const newToken = await user.refreshToken()

        if ( newToken?.status === 200 ) { return (await user.auth(undefined, undefined, true))?.status === 200 }
        
        return false
    },

    availableEmail: (email) => MakeRequest.get(`users/emailcheck/${email}`),
    
    create: async (body) => MakeRequest.post("users", body),
    
    update: async (data, resetPass) => {

        const response = await MakeRequest.put(`users${resetPass ? "?resetPass=true" : ""}`, data)
        
        if ( response?.status === 200 && store.state.currentUser?.userData ) {
            
            Object.assign(store.state.currentUser.userData, Object.values(response.data)[0])
        }

        return response
    },

    resendValidEmail: (email) => MakeRequest.post("auth/emailvalidation/" + email, {}),

    sendResetEmail: (email) => MakeRequest.post("auth/resetpass", { email }),

    checkResetCode: (body) => MakeRequest.post("auth/resetpasscheck", body),
}

export const install = {

    slango: () => MakeRequest.get('storage/installer', { responseType: 'blob' }),

    recorder: () => MakeRequest.get('storage/clickAndClickInstaller', { responseType: 'blob' }),

    getDesktopVersions: () => MakeRequest.get("autoupdate-clickandclick/versions")
}

export const scripts = {

    getSettings: (id) => MakeRequest.get("userscriptsettings?script=" + id),

    createSetting: (id, data) => MakeRequest.post("userscriptsettings/" + id, data),
    
    deleteSetting: (id) => MakeRequest.delete("userscriptsettings/" + id)
}

// ===============| Internationalization |===============

export async function GetTranslations(language = 'en') {

    return await MakeRequest.get(`internationalization/${language}?field=single`)
}

// ==================== API - TICKETUSER ====================

export async function CreateUserTicket(ticket){ //Enviar ticket de usuario
    return await MakeRequest.post("tickets/", ticket);
}

export async function GetUserTickets(sortBy, orderDir, startnum, type, status){ //Obtener tickets de usuario sortBy: ordenar por ,orderDir ordenar ascendete o descendente
    return await MakeRequest.get(`tickets/?type=${type}&status=${status}&sortby=${sortBy}&sortdir=${orderDir}&start=${startnum}&limit=10`);
}

export async function GetUserTicketByID(id){ //Obtener tickets de usuario sortBy: ordenar por ,orderDir ordenar ascendete o descendente
    return await MakeRequest.get(`tickets/${id}`);
}

export async function PostReplieForTicket(ticketid,body){ //Enviar respuesta a un ticket
    return await MakeRequest.post(`tickets/reply/${ticketid}`, body);
}

// ==================== API - SCRIPTS ====================

export async function GetScripts(query) {

    return await MakeRequest.get('scripts' + query)
}

export async function GetScriptCode(scriptID){
    return await MakeRequest.get(`storage/scripts/code/${scriptID}`);
}

export async function SearchScriptsByName(search){
    return await MakeRequest.get(`search/scripts/${search}`);
}

export async function GetRecommendedScripts(){
    return await MakeRequest.get("scripts?sample=4&select=name%20imgPath");
}

export async function GetFakeCards(){
    return await MakeRequest.get("scripts?isFake=true");
}

export async function RequestScript(scriptID){
    return await MakeRequest.post(`scriptrequest/${scriptID}`, {});
}


// ==================== API - CATEGORIES ====================

export async function GetCategories(){ // Obtiene las categorias existentes.
    return await MakeRequest.get("categories?getscripts=true&getFakeSample=1");
}


// ==================== API - TAGS ====================

export async function GetTags(){ // Obtiene los tags existentes.
    return await MakeRequest.get("tags");
}
export async function GetScriptsByTags(tagIDs){ // Obtiene los scripts de los tags seleccionados.
    return await MakeRequest.get(`tags?getscripts=true&ids=${tagIDs}`);
}

export async function GetUserScriptSettings(id) {
    return await MakeRequest.get("userscriptsettings?script=" + id);
}

export async function DeleteUserScriptSettings(id) {
    return await MakeRequest.delete("userscriptsettings/" + id);
}

export async function ServerScriptExecution(params, scriptID) {

    if ( typeof params.arguments !== 'object' ) { console.log('ServerExecution - Passed arguments must be object'); return }

    const script = 'sid/' + scriptID

    const query = []
    if ( params.step ) { query.push('step=' + params.step) }
    if ( params.token ) { query.push('executionToken=' + params.token) }

    const formData = new FormData()

    if ( !params.json ) {

        Object.keys(params.arguments).forEach((key) => {
    
            if ( Array.isArray(params.arguments[key]) ) {
    
                params.arguments[key].map(e => typeof e === 'object' ? e instanceof File ? e : JSON.stringify(e) : e).forEach((item) => { formData.append(key, item) })
            }
    
            else { formData.append(key, params.arguments[key]) }
        })
    }

    return await MakeRequest.post(`scriptexecution/${script}?${query.join('&')}`, params.json ? params.arguments : formData, { timeout: TIMEOUT })
}

export async function ServerStopScriptExecution(executionArguments/*, userTaskID, scriptID*/) {
    //const scriptIDToLaunch = userTaskID? 'usid/' + userTaskID : 'sid/' + scriptID

    let formData = new FormData();

    Object.keys(executionArguments).forEach((key) => {

        if ( typeof executionArguments[key] === 'object' ) {
            executionArguments[key].forEach((item) => { formData.append(key, item) })
        } else {
            formData.append(key, executionArguments[key])
        }
    })

    return await MakeRequest.put('scriptexecution/stop/' + executionArguments, formData, { timeout: TIMEOUT })
}

export async function DownloadServerExecutionOutput(id) {
    return await MakeRequest.get(`scriptexecution/getfile/${id}`, { responseType: "arraybuffer" }).catch(error => error);
}


// ==================== API - SHORTCUTS ====================


export const shortcuts = { 

    get: (query = "") => MakeRequest.get('userpageshortcut' + query, {}).catch(error => error),

    post: (shortcut) => MakeRequest.post('userpageshortcut', shortcut).catch(error => error),

    delete: (id) => MakeRequest.delete('userpageshortcut/' + id, {}).catch(error => error)
}

// ==================== API - CERTIFICATES ====================


export const digitalCertificates = {

    post: (query = "", body) => MakeRequest.post('digitalcertificate' + query, body),

    get: (query = "") => MakeRequest.get('digitalcertificate' + query ),

    delete: (body) => MakeRequest.delete('digitalcertificate', body )
}

// ==== Google =======

export const google = {

    verify: async (access_token) => {
        
        return axios.get('https://oauth2.googleapis.com/tokeninfo?access_token=' + access_token, { withCredentials: false })
    }
}

export async function GoogleCalendarEvents(id){

    return await axios.get(`https://www.googleapis.com/calendar/v3/calendars/${id}/events?key=${process.env.VUE_APP_GOOGLE_CALENDAR_API_KEY}`, { withCredentials: false })
        .catch((error) => HandleAPIErrors(error.response));
}

export async function GoogleGetCredentials(data){

    return await axios.post('https://www.googleapis.com/oauth2/v4/token', data, { withCredentials: false })
        .catch((error) => HandleAPIErrors(error.response));
}

// ===== MACRO RECORDER =====

export async function GetMacroRecordClientWebSocketPort() {
    return await MakeRequest.get('macro/client-ws-port');
}

export async function GetPublicRobots() {
    return await MakeRequest.get('browserflow/flow?populate=group');
}

// ==== SCRAPER ====

export async function GetProductsToScrap() {
    return await MakeRequest.get('auction-product/get-all');
}

export async function AddProductToScrap(data) {
    return await MakeRequest.post('auction-product/new', data);
}

export async function UpdateProductToScrap({ id, ...data }) {
    return await MakeRequest.put('auction-product/' + id, data);
}

export async function DeleteProductToScrap(id) {
    return await MakeRequest.delete('auction-product/' + id);
}

export async function DownlaodMiniRoboteka() {
    const response = await MakeRequest.get('miniroboteka', { responseType: 'blob' });

    return response.data;
}

// ==== GENERIC ERROR ====

// ========== ROBOTEKA ==========

export async function SendNewTaskRequest(technologies, description) {
    return await MakeRequest.post('suggest-robot', { technologies: technologies.toString(), description });
}

export async function AddPhoneToNewTaskRequest(requestID, phone) { return await MakeRequest.patch('suggest-robot/' + requestID, { phone })}

// =============== DOCUMENT BUILDER ===============

// ======== Categories ========

export async function DocumentCreator_GetTemplateCategories(query = '') {
    return await MakeRequest.get('documentcreator/documentcategory' + query);
}

export async function DocumentCreator_CreateTemplateCategory(name, parentCategory) {
    return await MakeRequest.post('documentcreator/documentcategory', {
        name,
        parentCategory: typeof parentCategory === 'string' ? parentCategory : undefined,
    });
}

export async function DocumentCreator_UpdateTemplateCategory(id, data) {
    return await MakeRequest.put(`documentcreator/documentcategory/${id}`, data);
}

export async function DocumentCreator_DeleteTemplateCategory(id) {
    return await MakeRequest.delete(`documentcreator/documentcategory/${id}`);
}

// ======== Templates =======

export async function DocumentCreator_GetUserTemplates(query = '') { // Templates
    return await MakeRequest.get('documentcreator/documenttemplate' + query);
}

export async function DocumentCreator_CreateDocumentTemplate(data) { // Templates
    return await MakeRequest.post('documentcreator/documenttemplate', data);
}

export async function DocumentCreator_UpdateDocumentTemplate(id, data) { // Templates
    return await MakeRequest.put(`documentcreator/documenttemplate/${id}`, data);
}

export async function DocumentCreator_DeleteUserTemplate(id) {
    return await MakeRequest.delete(`documentcreator/documenttemplate/${id}`);
}

export async function DocumentCreator_DeleteTemplate(id) {
    return await MakeRequest.delete(`documentcreator/documenttemplate/${id}`);
}


export async function DocumentCreator_DownloadTemplate(query) {
    return await MakeRequest.get('documentcreator/documenttemplate/download' + query, { responseType: "arraybuffer" }).catch(error => error);
}


// ======== Documents =======

export async function DocumentCreator_GetUserDocuments(query = '') {
    return await MakeRequest.get('documentcreator/document' + query);
}

export async function DocumentCreator_CreateDocument(data) {
    const documentBlocks = data.blocks
    data.blocks=[]
  
    return await MakeRequest.post('documentcreator/document', data)
    .then( async response => {
        const documentID = Object.keys(response.data)[0]
            for(let i=0; i < documentBlocks.length;i++){
                await MakeRequest.put('documentcreator/document/'+ documentID, {block:documentBlocks[i]})
            }                  
            return response
        })
}

export async function DocumentCreator_FillAndCreateMassDocuments(query, body) {

    let formData = new FormData();

    Object.entries(body).forEach(entry => formData.append(...entry));


    return await MakeRequest.post('documentcreator/document/fillanddownload' + query, formData, { responseType: "arraybuffer" }).catch(error => error);

}

export async function DocumentCreator_DeleteDocument(query) {
    return await MakeRequest.delete(`documentcreator/document/${query}`);
}

export async function DocumentCreator_DownloadDocument(query) {
    return await MakeRequest.get('documentcreator/document/download' + query, { responseType: "arraybuffer" }).catch(error => error);
}

// ======== Document Style ======

export async function DocumentCreator_GetDocumentStyles() { // Document Style
    return await MakeRequest.get('documentcreator/documentstyle?populate=watermark');
}

export async function DocumentCreator_CreateDocumentStyle(data) { // Document Style
    return await MakeRequest.post('documentcreator/documentstyle', data);
}

export async function DocumentCreator_UpdateDocumentStyle(id,data) { // Document Style
    return await MakeRequest.put('documentcreator/documentstyle/'+id, data);
}

export async function DocumentCreator_DeleteDocumentStyle(id) { // Document Style
    return await MakeRequest.delete('documentcreator/documentstyle/'+id);
}
// ======== Blocks =======
export async function DocumentCreator_GetContentBlocks(params) { // Content blocks
    const query = ['populate=tags']
    if ( params ) { query.push(params) }

    return await MakeRequest.get('documentcreator/blocktemplate?' + query.join('&'));
}

export async function DocumentCreator_CreateContentBlock(data) { // Content blocks
    return await MakeRequest.post('documentcreator/blocktemplate', data);
}

export async function DocumentCreator_UpdateContentBlock(id, data) { // Content blocks
    return await MakeRequest.put(`documentcreator/blocktemplate/${id}`, data);
}

export async function DocumentCreator_DeleteContentBlock(id) { // Content blocks
    return await MakeRequest.delete(`documentcreator/blocktemplate/${id}`);
}

export async function DocumentCreator_SearchContentBlocks(text) { // Templates
    return await MakeRequest.get(`documentcreator/search/blocks/${encodeURIComponent(text)}`);
}

export async function DocumentCreator_SearchUserTemplates(text) { // Templates
    return await MakeRequest.get(`documentcreator/search/templates/${encodeURIComponent(text)}`);
}

export async function DocumentCreator_GetContentBlockTags() {
    return await MakeRequest.get('documentcreator/documentblocktag/');
}

export async function DocumentCreator_GetContentBlockByTagId(tagID) {
    return await MakeRequest.get(`documentcreator/documentblocktag/${tagID}`);
}

export async function DocumentCreator_DownloadSubmissions(query) {
    return await MakeRequest.get('documentcreator/document' + query, { responseType: "arraybuffer" });
}

// ======== Online Forms =======

export async function OnlineForm_GetForms(query = '') {
    return await MakeRequest.get('documentcreator/form' + query);
}

export async function OnlineForm_UpdateForm(id, data,removeBanner) {
    return await MakeRequest.put(`documentcreator/form/${id}?removeBanner=${removeBanner}`, data);
}

export async function OnlineForm_DeleteForms(query) {
    return await MakeRequest.delete(`documentcreator/form${query}`);
}

export async function OnlineForm_GetData(id) {
    return await MakeRequest.get(`documentcreator/form/data/${id}`);
}

export async function OnlineForm_SendForm(id, data) { // Templates
    return await MakeRequest.post(`documentcreator/formsubmission/${id}`, data);
}

export async function OnlineForm_GetSubmissions(query = '') {
    return await MakeRequest.get('documentcreator/formsubmission' + query);
}

export async function OnlineForm_DeleteSubmissions(id) {
    return await MakeRequest.delete(`documentcreator/formsubmission/${id}`);
}

export async function OnlineForm_DownloadDocument(data) {
    return await MakeRequest.post('documentcreator/formsubmission/generateDocument', data, { responseType: "arraybuffer" }).catch(error => error);
}

//==================== Online Form ========================

export async function CreateOnlineFormByTemplate(templateId, body){
    return await MakeRequest.post(`documentcreator/form/${templateId}`, body);
}

export async function UploadUserFile(body){
    let formData = new FormData();

    Object.entries(body).forEach(entry => formData.append(...entry));

    return await MakeRequest.post('storage/userfiles', formData);
}

//==================== INOPOL ========================
export async function GetInopolFormsData(city) { return await MakeRequest.get('documentcreator/form/adhoc-settings/inopol?city=' + city) }

//==================== INFO ========================
export async function GetInfoFormsData() { return await MakeRequest.get('documentcreator/form/adhoc-settings/info') }

// ========== SHARING ==========

export async function GetSharingCandidates() {
    return await MakeRequest.get('share/candidates');
}

export async function GetSharedOwn(shareable = undefined, other = undefined) {
    return await MakeRequest.get([ 'share/own', shareable, other ].filter(e => e).join('/'));
}

export async function SetSharedOwnAs(shareable, other, access) {
    return await MakeRequest.put(`share/own/${shareable}/${other}`, { access });
}

export async function GetSharedWithMe(shareable, includeOwnedUnshared = false) {
    return await MakeRequest.get(`share/me/${shareable}${includeOwnedUnshared ? '?include_owned_unshared=true' : ''}`);
}

export async function GetSharedObject(shareable) {
    return await MakeRequest.get(`share/object/${shareable}`);
}

export async function GetSharedObjectWithKey(shareable, object) {
    return await MakeRequest.get(`share/objectkey/${shareable}/${object}`);
}

export async function EditSharedObject(shareableId, newData) {
    return await MakeRequest.put(`share/edit/${shareableId}`, newData);
}

export async function CloneSharedObject(shareableId, newData = {}) {
    return await MakeRequest.post(`share/clone/${shareableId}`, newData);
}

export async function GetReducedUserInfo(userId) {
    return await MakeRequest.get(`share/reduceduserinfo/${userId}`);
}

export async function GetReducedGroupInfo(groupId) {
    return await MakeRequest.get(`share/reducedgroupinfo/${groupId}`);
}

export async function GetUpdatesNotes() {
    return await MakeRequest.get('updates-notes');
}

export async function GetActiveUpdatesNote() {
    return await MakeRequest.get('updates-notes/active');
}

export async function SearchBoe(text) {
    const formData = new FormData();
    formData.set('content', text);
    return await MakeRequest.post('https://search-service--zsnyhzc.delightfulmoss-8d5f89e2.westeurope.azurecontainerapps.io/search/ai', formData, { withCredentials: false });
}

export async function DownloadBoePdf(id) {
    return await MakeRequest.get(`https://search-service--zsnyhzc.delightfulmoss-8d5f89e2.westeurope.azurecontainerapps.io/search/pdf/${id}`, { withCredentials: false, responseType: 'blob' });
}

export const subscription = {

    get: () => {},

    balance: () => MakeRequest.get('usage-report').then(r => { if ( r?.status === 200 ) { store.state.currentUser.usedSlans = r.data.totalUsage } }),

    prices: () => MakeRequest.get('subscriptions/getproductprices'),

    createSetupIntent: () => MakeRequest.get('subscriptions/createsetupintent'),

    getPaymentMethod: (id) => MakeRequest.get(`subscriptions/getPaymentMethodById/${id}`),

    update: async (subPrice, paymentMethodId) => {
        
        const res = await MakeRequest.put('subscriptions/updateUsageSubscription/', { subPrice, paymentMethodId })
        await subscription.balance()
        
        return res
    },

    updateBillingInfo: (invoiceData) => MakeRequest.put('subscriptions/updatebillinfo', { invoiceData }),

    removePaymentMethod: (id) => MakeRequest.put(`subscriptions/removePaymentMethod/${id}`, {}),

    cancel: () => MakeRequest.put('subscriptions/cancelSubscription', {}),

    addPaymentMethod: () => MakeRequest.put('subscriptions/checkout-session', {})
}

export const flows = {

    get: () => MakeRequest.get('browserflow/flow?populate=group&sortby=name'),

    getById: (id) => MakeRequest.get(`browserflow/flow/${id}?populate=group`),

    getLogs: (query = '') => MakeRequest.get(`browserflow/flowlog`+ query),
    
    getLogsStats: (query = '') => MakeRequest.get(`browserflow/flowlog/stats`+ query),

    getLogsReport: (query = '') => MakeRequest.get(`browserflow/flowlog/report`+ query),

    schedule: (query, body) => MakeRequest.post(`browserflow/schedule` + query, body),

    getSchedule: (query) => MakeRequest.get(`browserflow/schedule` + query),

    deleteSchedule: (id) => MakeRequest.delete(`browserflow/schedule/` + id),
}

export const frumecar = {

    order: {

        get: (query = '') => MakeRequest.get('frumecar/frumecar-order' + query),

        getComments: (id) => MakeRequest.get('frumecar/frumecar-order/comments/' + id),

        getAttachments: (id) => MakeRequest.get('frumecar/frumecar-order/attachments/' + id),

        post: (orderList) => MakeRequest.post('frumecar/frumecar-order', orderList),

        delete: (ids) => MakeRequest.delete(`frumecar/frumecar-order?ids=${ids.join()}`),

        put: (id, data) => MakeRequest.put(`frumecar/frumecar-order/${id}`, data),
        
        updateBCInfo: (id) => MakeRequest.put(`frumecar/frumecar-order/bussinessCentral/${id}`, {} ),

        readVariablesFromPdf: (id, file) => {
            const formData = new FormData()
            formData.append("file", file)
            return MakeRequest.put(`frumecar/frumecar-order/pdf/${id}`, formData )
        },
    },

    variableSettings: {

        get: (query = '') => MakeRequest.get('frumecar/frumecar-variable-settings' + query),
    },

    manufacturers: (query = '') => MakeRequest.get('frumecar/frumecar-mfr-settings' + query),

    generateDocuments: (id, documentType, body = null) => MakeRequest.post(`frumecar/frumecar-order/document/${id}/${documentType}`, body, { responseType: "arraybuffer" }),
}

export const notifications = {

    get: (query = '') => MakeRequest.get('dehu-notification' + query),
}

export const formToFlow = {

    //get Form to flow by flow id

    get: (id, query = '') => MakeRequest.get(`form-to-flow/${id}` + query), //?populate=flow,group,fieldGroups"

    form: {

        auth: (credentials) => {

            const referer = { 'X-Slango-Referer': location.host + location.pathname }
            
            return MakeRequest.post('form-to-flow-form', { ...credentials }, { headers: { ...referer } }, false)
        },

        get: (id) => MakeRequest.get('form-to-flow-form/' + id),

        submit: (id, fields) => {
            const referer = { 'X-Slango-Referer': location.host + location.pathname }
            return MakeRequest.post('form-to-flow-form/' + id, fields, { headers: { ...referer } })
        },

        put: (form) => {
            
            let formData = new FormData();
            Object.entries(form).forEach(entry => formData.append(...entry));

            return MakeRequest.put("users/formToFlow", formData)
        }
        
        
    },

    client: {

        //create and update ftf client
        patch: (formToFlowClient) => MakeRequest.patch("form-to-flow-client",formToFlowClient),
        
        //get ftf client
        get: (formToFlowConfigurationId, query = '') => MakeRequest.get(`form-to-flow-client/${formToFlowConfigurationId}` + query ), //?populate=credentials.client"

    },

    user: {

        //get ftf user
        get: (query = '') => MakeRequest.get("form-to-flow-user" + query),
        //send email with access to form to user
        sendemail: (ftfuserId) => MakeRequest.put(`form-to-flow-user/sendemail/${ftfuserId}`),

        client: {

            get: () => MakeRequest.get("form-to-flow-user/client"),

            post: (formToFlowUserClient) => MakeRequest.post("form-to-flow-user/client", formToFlowUserClient),

            patch:(id, formToFlowUserClient) => MakeRequest.patch(`form-to-flow-user/client/${id}`, formToFlowUserClient ),

            delete:(userId) => MakeRequest.delete(`form-to-flow-user/client/${userId}`)
        }
    },

    group: {

        get: (clientId, groupKey = '') => MakeRequest.get(`form-to-flow-client-group/${clientId}${groupKey ? `/${groupKey}` : ''}`),

        post: (clientId, groupKey, group) => MakeRequest.post(`form-to-flow-client-group/${clientId}/${groupKey}`, group),

        patch: (clientId, groupKey, group) => MakeRequest.patch(`form-to-flow-client-group/${clientId}/${groupKey}`, group),

        delete: (clientId, groupKey, groupId) => MakeRequest.delete(`form-to-flow-client-group/${clientId}/${groupKey}/${groupId}`)

    },

    configuration: {

        patch: (flowId, configuration) => MakeRequest.patch(`form-to-flow-configuration/${flowId}`, configuration),

        get: (flowId, query = "") => MakeRequest.get(`form-to-flow-configuration/${flowId}`+ query) //?populate=formToFlow,flow,group

    },

    submissions: {

        get: (query = '') => MakeRequest.get(`form-to-flow-submission` + query), //?populate=ftf, ftfclient, ftfConfiguration, ftfUserClient & ftfId={formToFlowId}"

        getById: (submissionId) => MakeRequest.get(`form-to-flow-submission?populate=ftf, ftfclient, ftfConfiguration, ftfUserClient & ftfId=${submissionId}`),

        patch: (submissionId, submission) => MakeRequest.patch(`form-to-flow-submission/${submissionId}`, submission),

        delete: (submissionId) => MakeRequest.delete(`form-to-flow-submission/${submissionId}`)
    }
}

export const contact = {

    demo: () => MakeRequest.post('requestdemo', {})
}

export const recorder = {

    installer: () => MakeRequest.get('storage/clickAndClickInstaller', { responseType: "arraybuffer" }),
    
    get: () => MakeRequest.get('macro/get-all'),
    
    delete: (id) => MakeRequest.delete(`macro/delete/by-id/${id}`)
}

export const fie = {

    create: (body) => MakeRequest.post('fie', body),

    get: (query = "") => MakeRequest.get('fie' + query),

    delete: (id) => MakeRequest.delete('fie/' + id),
}

export const tutorial = {

    get: (query = "") => MakeRequest.get('user-tutorials' + query)
}